import { objectToURL } from "./misc";

export const safeRoutes = {
  admin: {
    businesses: "/admin/businesses",
    home: "/admin",
    users: "/admin/users",
    businesses$idText: {
      home: (ownerId: string) => `/admin/businesses/${ownerId}/text`,
      queryBusinessName: (ownerId: string, businessPrimaryName: string) =>
        `/admin/businesses/${ownerId}/text?=${businessPrimaryName}`,
    },
  },
  api: {
    apiUtilsServer: "/api/api-utils/server",
    admin: {
      lenders: {
        getAll: "/api/admin/lenders/get-all",
      },
    },
    veriffDecision: "/api/veriff-decision",
    veriffEvent: "/api/veriff-event",
    enigma: {
      updateEnigmaId: "/api/enigma/update-enigma-id",
    },
    creditReports: {
      deleteCreditReport: "/api/credit-report/delete-credit-report",
    },
  },
  business: {
    add: "/business/add",
    home: (slug?: string) => `/business/${slug}/home`,
    creditReports: {
      home: (slug?: string) => `/business/${slug}/credit-reports`,
      find: (slug?: string) => `/business/${slug}/credit-reports/find`,
      equifaxDetails: (slug?: string) =>
        `/business/${slug}/credit-reports/equifax-details`,
      details: (slug?: string) => `/business/${slug}/credit-reports/details`,
      equifax: (slug?: string) => `/business/${slug}/credit-reports/equifax`,
      paymentHistory: (slug?: string, loanId?: string) =>
        `/business/${slug}/credit-reports/payment-history/${loanId}`,
    },
    manage: {
      account: {
        index: "/business/manage/account",
        edit: "/business/manage/account/edit",
      },

      index: "/business/manage",
      edit: "/business/manage/edit",
    },

    program: "/business/program",
    subscriptionManagement: "/business/subscription-management",

    learningCenter: {
      home: "/business/learning-center",
      resourceId: (resourceId: string) =>
        `/business/learning-center/${resourceId}`,
    },

    programs: {
      checklist: "/business/programs/checklist",
      grants: "/business/programs/grants",
      localBenchmark: "/business/programs/local-benchmark",
      home: "/business/programs",
      programSlug: (programSlug: string) => `/business/programs/${programSlug}`,
    },
    subscription: {
      home: "/business/subscription-management",
      originPageToSubscription: (subscriptionType: string) =>
        objectToURL("/business/subscription-management", {
          "subscribed-for": subscriptionType,
        }),
      subscriptionRedirectPayment: (
        baseURL: string,
        subscriptionType: string,
      ) =>
        objectToURL(baseURL, {
          "subscribed-for": subscriptionType,
        }),
      subscriptionWithProgramSlug: (
        baseURL: string,
        subscriptionType: string,
        programSlug: string,
      ) =>
        objectToURL(baseURL, {
          "subscribed-for": subscriptionType,
          "program-slug": programSlug,
        }),
      paymentStatus: "/business/subscription-management/payment-status",
      donation: "/business/donation-management",
    },
  },
  home: {
    index: "/",
  },
  login: "/login",
  logout: "/logout",
  signupSuccess: "/signup-success",
  auth: {
    index: "/auth",
    signUp: "/auth/signup",
    signUpVerify: "/auth/verify",
    signIn: "/auth/login",
    signOut: "/auth/logout",
    verifyEmailLink: "/auth/verify-link",
  },
  invites: {
    base: "/invite",
    purchase: (inviteSlug: string, code: string, email?: string) =>
      `/invite/${inviteSlug}/${code}/purchase${email ? `?email=${email}` : ""}`,
  },
};

export const getHostname = (request: Request): string => {
  const url = new URL(request.url);
  const hostname = `${url.protocol}//${url.host}`;
  return new URL(hostname).toString();
};
